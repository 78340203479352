@import "../../../../styles/variables.scss";

.wrapper {
  max-width: 534px;
  max-height: 230px;
  margin: 0 auto 24px auto;

  @media (max-width: $bpTablet) {
    max-width: 328px;
    max-height: 136px;
  }

  @media (max-width: $bpMobileMedium) {
    max-width: 100%;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 72px);
  gap: 6px;

  @media (max-width: $bpTablet) {
    grid-template-rows: repeat(3, 43px);
    gap: 3px;
  }
}
