@import "../../styles/variables.scss";

.items {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: $bpMobileLarge) {
  .items {
    flex-direction: column;
    margin-top: 26px;
  }
}
