@import "../../../styles/variables.scss";

.subTitile {
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  margin: 24px 0;

  @media (max-width: $bpTablet) {
    font-size: 15px;
    line-height: 18px;
    margin: 20px 0;
  }

  @media (max-width: $bpMobileLarge) {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 360px;
  margin-bottom: 24px;

  @media (max-width: $bpTablet) {
    max-width: 344px;
    margin-bottom: 20px;
  }

  @media (max-width: $bpMobileLarge) {
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.button {
  margin: 0 auto;

  @media (max-width: $bpMobileSmall) {
    width: 100%;
  }
}
