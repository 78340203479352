@import '../../styles/_variables.scss';

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;
	margin-bottom: 26px;
	font-size: 14px;
	line-height: 140%;
}

.logo {
	max-width: 60px;
	max-height: 60px;
	img {
		vertical-align: middle;
		width: 100%;
		object-fit: cover;
	}
}

.mainMenuButton {
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	width: 25px;
	height: 20px;
	cursor: pointer;
	span {
		width: 16px;
		height: 2px;
		background-color: $background;
		&:first-child {
			width: 20px;
			position: absolute;
			top: 3px;
		}
		&:last-child {
			width: 8px;
			position: absolute;
			bottom: 3px;
		}
	}
}

.returnButton {
	display: none;
	width: 25px;
	height: 25px;
	justify-content: flex-start;
	align-items: center;
}

.title {
	display: none;
	margin: 0 10px 0 50px;
	color: $background;
}

.mainMenu {
	flex: 1 1 auto;
	position: relative;
	&__closeButton {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 16px;
		top: 12px;
		width: 18px;
		height: 18px;
		cursor: pointer;
		span {
			position: absolute;
			width: 24px;
			height: 2px;
			background-color: $background;
			&:first-child {
				transform: rotate(45deg);
			}
			&:last-child {
				transform: rotate(-45deg);
			}
		}
	}
	&__list {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		gap: 24px;
		li {
			position: relative;
			a {
				transition: color 0.2s ease-in-out;
				svg path{
					transition: stroke .2s ease-in-out;
				}
				&:hover {
					color: $EmphasiseDefault;
					svg path{
						stroke: $EmphasiseDefault;
					}
				}
			}
			&:last-child {
				margin-right: 60px;
			}
		}
	}
	&__link {
		&__sub {
			display: flex;
			align-items: center;
			gap: 4px;
			
		}
		
	}
}

.subMenu {
	&__about {
		display: none;
		position: absolute;
		white-space: nowrap;
		padding: 12px;
		margin-top: 10px;
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
		z-index: 5;
		background: $background;
		li {
			line-height: 20px;
			button {
				transition: color 0.2s ease-in-out;
				cursor: pointer;
				&:hover {
					color: $EmphasiseDefault;
				}
			}
		}
		li:not(:last-child) {
			margin-bottom: 6px;
		}
		&.active {
			display: block;
		}
	}
}

.icons {
	display: flex;
	align-items: center;
	// &__auth {
	//   a {
	//     display: flex;
	//     align-items: center;
	//     span {
	//       margin-left: 11px;
	//     }
	//   }
	// }
}

@media (max-width: $bpTablet) {
	.wrapper {
		padding: 24px 0;
		margin-bottom: 24px;
	}

	.logo {
		max-width: 40px;
		max-height: 40px;
	}

	.mainMenu {
		&__list {
			justify-content: center;
			gap: 16px;
			li {
				&:last-child {
					margin-right: 0;
				}
			}
		}

		&__link {
			&__sub::after {
				margin-left: 1px;
			}
		}
	}

	// .icons {
	//   // span {
	//   //   display: none;
	//   // }
	// }
}

@media (max-width: $bpMobileLarge) {
	.wrapper {
		padding: 16px 0;
		background-color: $EmphasiseDefault;
	}

	.logo {
		display: none;
	}

	.mainMenu {
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 360px;
		transform: translate(-360px, 0);
		background-color: $mobileMainMenuBackground;
		border-right: 1px solid $darkEmphasiseDefault;
		transition: all 0.3s ease 0s;
		&__list {
			flex-direction: column;
			align-items: flex-start;
			padding-left: $horizontalPaddingContainer;
			padding-top: 67px;
			padding-right: 16px;
			li {
				position: relative;
				width: 100%;
				a {
					color: $background;
					font-size: 16px;
					line-height: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					&::after {
						content: '';
						top: 1px;
						background: url(../../assets/icons/arrowMenuRight.svg) center no-repeat;
						width: 24px;
						height: 24px;
						margin-left: 4px;
					}
				}
			}
			li:not(:last-child) {
				margin-bottom: 23px;
			}
		}
		&.active {
			transform: translate(0, 0);
		}
	}

	.mainMenuButton {
		display: flex;
	}

	.returnButton {
		display: flex;
	}

	.title {
		display: block;
	}
}

@media (max-width: $bpMobileMedium) {
	.mainMenu {
		&__list {
			padding-left: $horizontalPaddingContainerMobileM;
		}
	}
}

@media (max-width: $bpMobileSmall) {
	.wrapper {
		padding-top: $horizontalPaddingContainerMobileS;
		padding-bottom: $horizontalPaddingContainerMobileS;
	}

	.mainMenu {
		width: 100%;
		border-right: hidden;
		transform: translate(-100%, 0);
		&__list {
			padding-left: $horizontalPaddingContainerMobileS;
		}
		&.active {
			transform: translate(0, 0);
		}
	}
}
