@import "../../../../styles/variables.scss";

.image {
  width: 300px;
  margin: 0 auto 24px auto;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.subTitle {
  text-align: center;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}

.description {
  text-align: center;
  line-height: 21px;
  margin-bottom: 24px;
}

.button {
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  .button {
    width: 100%;
    justify-content: center;
  }
}
