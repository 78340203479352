// === Colors ===========
$background: #ffffff;
$textColor: #000000;
$iconColor: #2b3558;
$errorColor: #f62424;
$EmphasiseDefault: #7dc82f;
$lightEmphasiseDefault: #bee397;
$darkEmphasiseDefault: #8cb346;
$linkFontColor: #8ed16f;
$mobileMainMenuBackground: #7dc82f;
$hoverDefault: #d1e1b5;
$darkBlue: #2b3558;
$border: #bfc2cd;
$btnCencelBGColor: #c4c4c4;
$btnDefaultBGColor: linear-gradient(156.52deg, #7dc82f -12.34%, #7dc82f 80.98%);
$btnHoverBGColor: linear-gradient(88.59deg, #8eba3d -23.21%, #93c042 99%);
$btnDisableBGColor: linear-gradient(
  156.52deg,
  rgba(132, 196, 82, 0.4) -12.34%,
  rgba(144, 221, 85, 0.4) 80.98%
);

// === Fonts ===========
$fontFamilyDefault: "Roboto", sans-serif;
$fontButtonDefault: "Roboto", sans-serif;
$fontSomeLinksDefault: "Roboto", sans-serif;
$fontSizeDefault: 16px;
$fontColorDefault: $textColor;

// === Sizes ===========
$maxWidthContainer: 1064px;
$horizontalPaddingContainer: 50px;
$horizontalPaddingContainerMobileM: 25px;
$horizontalPaddingContainerMobileS: 16px;
$totalWidthContainer: $maxWidthContainer + (2 * $horizontalPaddingContainer);

// === Break Points ===========
$bpTablet: 930px;
$bpMobileLarge: 720px;
$bpMobileMedium: 560px;
$bpMobileSmall: 460px;

// $maxWidth: 1064px;
// Минимальная ширина страницы
// $minWidth: 320px;

// Ширина ограничивающего контейнера (0 = нет ограничения)
// $maxWidthContainer: 1220;
// Общий отступ у контейнера
// (30 = по 15px слева и справа, 0 = нет отступа)
// $containerPadding: 12;
// // Ширина срабатывания первого брейкпоинта
// $containerWidth: $maxWidthContainer + $containerPadding;
// // $md1: $maxWidthContainer + 12;

// // === Break Points ===========
// $md1: $containerWidth; // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
// // $pc
// $md2: 1120; // Планшеты, некоторые телефоны в горизонтальном положении
// // $tablet: 991.98;
// $md3: 991.98; // Телефоны L
// // $mobile: 767.98;
// $md4: 850; // Телефоны S
// // $mobileSmall: 479.98;
// $md5: 479.98;
