@import "../../../../styles/variables.scss";


.wrapper {
  text-align: center;
}
.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: $darkEmphasiseDefault;
}
.changeForm{

  width: 92vw;
  height: 44px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding: 12px 16px;
  justify-content: start;
  align-items: center;
  font-family: $fontFamilyDefault;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  background: $background;
  box-shadow: 2px 2px 5px;
  color: #2B3558;
  border-radius: 6px;
}

.errorMessage {
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  color: $errorColor;
}
.formSection {
  position: relative;
  margin-bottom: 24px;
  label {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.description {
  text-align: center;
  line-height: 21px;
  margin-bottom: 24px;
}

.button {
  display: flex;
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  .button {
    width: 100%;
    justify-content: center;
  }
}
