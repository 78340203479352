@import "../../styles/variables";

.wrapper{
  display: flex;
  margin-top: 40px;
  &_menu{
    cursor: pointer;
    width: 144px;
    height: 132px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: $fontSizeDefault;
    line-height: 21px;
  }
}
.history,
.cards,
.settings {
  color: #000000;
  &_Opacity{
    opacity: 0.5;
  }
}

.mobileMenu{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

}
.mobileItem{
  width: 328px;
  height: 58px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 17px 16px;
  justify-content: space-between;
  font-family: $fontFamilyDefault;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  background: $background;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  color: #2B3558;
}

@media (max-width: $bpTablet) {
  .wrapper{
    display: flex;
    margin-top: 26px;
    &_menu{
      width: 140px;
      height: 86px;
      margin-right: 24px;
      font-size: 14px;
    }
  }
}
@media (max-width: $bpMobileLarge) {
  .mobileItem {
    width: 85vw;
  }
  .mobileMenu {
    width: 85vw;
  }
}
@media (max-width: $bpMobileMedium) {
  .mobileItem {
    width: 90vw;
  }
  .mobileMenu{
    width: 90vw;
  }
}

@media (max-width: $bpMobileSmall) {
  .mobileItem {
    width: 91.5vw;
  }
  .mobileMenu{
    width: 91.5vw;
  }
  .wrapper{
    margin-top: 0px;
  }
}



