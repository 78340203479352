@import "../../styles/variables";

.item {
  max-width: 328px;
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  justify-content: space-between;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  color: $textColor;
  &_trash {
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
  }
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}

.cardNumber{
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  &_cancel {
    background: $btnCencelBGColor;
  }
}
.button {
  background: $btnCencelBGColor;
  opacity: 50%;
  color: $textColor;
}

@media (max-width: $bpMobileLarge) {
 .item{
   max-width: none;
 }
}

@media (max-width: $bpMobileMedium) {
  .item{
    max-width: none;
  }
}

@media (max-width: $bpMobileSmall) {
  .item{
    max-width: none;
  }
}
