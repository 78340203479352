@import "../../../../styles/variables.scss";

.wrapper {
  text-align: center;
  .imageBlock {
    max-width: 300px;
    margin: 0 auto 24px auto;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .subTitle {
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 12px;
  }
}

.description {
  // !
  text-align: center;
  line-height: 21px;
  margin-bottom: 24px;
}

.button {
  // !
  display: flex;
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  // !
  .button {
    width: 100%;
    justify-content: center;
  }
}
