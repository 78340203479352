@import "../../../../styles/variables.scss";

.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: $darkEmphasiseDefault;
}

.errorMessage {
  //!
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  color: $errorColor;
}

.formSection {
  // !
  position: relative;
  margin-bottom: 24px;
  label {
    position: absolute;
    top: 13px;
    left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.description {
  // !
  text-align: center;
  line-height: 21px;
  margin-bottom: 24px;
  opacity: 50%;
}

.button {
  // !
  display: flex;
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  // !
  .button {
    width: 100%;
    justify-content: center;
  }
}
