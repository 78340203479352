@import "../../styles/variables.scss";

.icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: 4px;
  right: 4px;
  z-index: 5;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}
