@import "../../../../styles/variables.scss";

abbr[title] {
  text-decoration: none;
}

.react-calendar {
  max-width: 328px;
  background: $background;
  margin: 0 auto;
}

.react-calendar--doubleView {
  max-width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  justify-content: space-between;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  max-width: 328px;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  -webkit-appearance: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation .react-calendar__navigation__label:disabled {
  background-color: $background;
}

// .react-calendar__navigation button:enabled:focus {
// background-color: #e6e6e6;
// }

.react-calendar__navigation button:enabled:hover {
  background-color: #e6e6e6;
}

.react-calendar__month-view {
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 21px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

// .react-calendar__month-view__days__day--weekend {
// }

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  visibility: hidden;
  opacity: 0;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  color: $textColor;
  -webkit-appearance: none;
}

.react-calendar__tile:disabled {
  color: #e0e4f1;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: $EmphasiseDefault;
}

.react-calendar__tile--now {
  background: $EmphasiseDefault;
  border-radius: 50%;
  color: white;
  &:disabled {
    background: rgba(0, 0, 0, 0.4);
  }
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $hoverDefault;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

// .react-calendar__tile--active {
//   color: $darkEmphasiseDefault;
// }

// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//   background: #1087ff;
// }

// .react-calendar--selectRange .react-calendar__tile--hover {
//   background-color: #e6e6e6;
// }

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__divider {
    display: none;
  }
  &__labelText {
    flex: 1 1 auto;
    max-width: 284px;
    color: $EmphasiseDefault;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    &:first-child {
      padding-right: 44px;
    }
    &:last-child {
      padding-left: 44px;
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .react-calendar__month-view {
    &:first-child {
      margin-right: 0px;
    }
    &:last-child {
      margin-left: 0px;
    }
  }
}
