@import "../../../../styles/variables.scss";

.wrapper {
  background-color: $background;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  padding: 24px;
  flex: 0 0 410px;
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &:before {
    content: "";
    width: 25px;
    height: 25px;
  }
}

.type {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $textColor;
}

.deleteButton {
  padding-right: 28px;
  width: 25px;
  height: 25px;
  background-image: url("../../../../assets/icons/TrashIcon.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.body {
  margin-bottom: 26px;
}

.line_1 {
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  img {
    margin-right: 14px;
  }
}

.family_counter {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: max-content 1fr;
  gap: 12px;

  @media (max-width: $bpTablet) {
    gap: 8px;
  }
}

.line_2 {
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  img {
    margin-right: 14px;
  }
  &_bold{
    font-weight: bold;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.fs_italic {
  font-style: italic;
}

@media (max-width: $bpTablet) {
  .wrapper {
    padding: 16px;
    flex: 0 0 312px;
  }

  .header {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .type {
    font-size: 15px;
    line-height: 20px;
  }

  .body {
    margin-bottom: 14px;
  }

  .title {
    font-size: 13px;
    line-height: 15px;
  }

  .date {
    font-size: 13px;
    line-height: 15px;
  }

  .price {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: $bpMobileLarge) {
  .wrapper {
    flex: 1 1 328px;
  }
}
