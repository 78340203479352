@import "../../../../styles/variables";

.wrapper {
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px;
  line-height: 21px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  color: #2B3558;
  &_header{
    font-family: $fontFamilyDefault;
    color: $textColor;
    line-height: 23px;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.member{
  width: 300px;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.boxHeader{
  margin-top: 12px;
  line-height: 21px;
}

.includes{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.includes_item{
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.expiration{
  font-style: italic;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
}

//small render styles
.wrapper_small{
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px;
  line-height: 21px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  color: #2B3558;
  &_header{
    font-family: $fontFamilyDefault;
    color: $textColor;
    line-height: 23px;
    font-weight: 600;
    font-size: 20px;
    box-shadow: none;
    margin-bottom: 0px;
  }
}

.includes_small{
  img{
    margin-top: -3px;
  }
  font-style: italic;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &_containterSmall{
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 274px;
    height: 78px;
  }
}

.includes_item_text{
  margin-left: 13px;
  &_foottext{
    text-align: right;
  }
}

.status_text{
  margin-left: 4px;
  font-style: normal;
}

.status{
  margin-top: 0;
  font-weight: 600;
  color: $mobileMainMenuBackground;
}

.about{
  width: 274px;
  font-style: italic;
  font-size: 14px;
  line-height: 16px;
  opacity: 50%;
  text-align: left;
}

.pretender {
  display: flex;
  gap: 25px;

  &_title {
    font-weight: bold;
  }

  &_list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}



@media (max-width: $bpTablet) {
  .wrapper{
    width: 233px;
    gap: 0px;
    padding: 12px;
    &_header{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .member{
    width: 210px;
    font-size: 11px;
    line-height: 13px;
  }
  .boxHeader{
    font-size: 11px;
    line-height: 13px;
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .includes_item_text{
    font-size: 11px;
    line-height: 13px;
      &_foottext{
        font-size: 11px;
        text-align: right;
      }
  }
  .expiration{
    font-size: 11px;
    line-height: 13px;
  }
  .about{
    width: 209px;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
  }
  .includes_item{
    font-size: 11px;
    line-height: 13px;
  }
  .wrapper_small{
    width: 233px;
    gap: 20px;
    padding: 12px;
    &_header{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .status{
    font-size: 14px;
    margin-top: -2px;
  }
  .includes_small{
    &_containterSmall{
      width: 209px;
    }
  }

  .pretender {
    display: flex;
    gap: 15px;
    font-size: 11px;
    margin-top: 10px;
    line-height: 16px;

    &_title {
      font-weight: bold;
    }

    &_list {
      display: flex;
      flex-direction: column;
      gap: 0
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .wrapper{
    width: 92vw;
    font-size: 14px;
  }
  .about{
    text-align: left;
  }
  .wrapper_small{
    width: 92vw;
  }
  .includes_small{
    &_containterSmall{
      margin-left: 20px;
      width: 92vw;
    }
  }
  .includes_item_text{
    font-size: 14px;
    line-height: 16px;
    &_foottext{
      font-size: 14px;
    }
  }
}

@media (max-width: $bpMobileMedium) {
  .wrapper{
    width: 92vw;
    font-size: 14px;
  }
  .about{
    text-align: left;
  }
  .wrapper_small{
    width: 92vw;
  }
  .includes_small{
    &_containterSmall{
      margin-left: 20px;
      width: 92vw;
    }
  }
  .includes_item_text{
    font-size: 14px;
    line-height: 16px;
    &_foottext{
      font-size: 14px;
    }
  }
}

@media (max-width: $bpMobileSmall) {
  .wrapper{
    width: 92vw;
    font-size: 14px;
  }
  .about{
    text-align: left;
  }
  .wrapper_small{
    width: 92vw;
  }
  .includes_small{
    &_containterSmall{
      margin-left: 20px;
      width: 92vw;
    }
  }
  .includes_item_text{
    font-size: 14px;
    line-height: 16px;
    &_foottext{
      font-size: 14px;
    }
  }
}
