@import "../../styles/variables.scss";

.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 14px;
  color: $darkEmphasiseDefault;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  &_method:not(:last-child) {
    margin-bottom: 40px;
  }
}

.subTitile {
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 24px;
}

.item {
  width: 328px;
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  justify-content: flex-start;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  color: #2b3558;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}

.modalText {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
}

.button {
  margin: 34px auto 0 auto;
  span {
    color: $background;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 12px;
      width: 12px;
      height: 10px;
      background-image: url("../../assets/icons/arrowIndicRight.svg");
    }
  }
}
