@import "../../../../styles/variables";

.wrapper {
  cursor: pointer;
  width: 328px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: #FFFFFF;
  color: #2B3558;
  margin-top: 24px;
  &_item{
    border-bottom: 1px solid #8AD154;
    padding-bottom: 13.5px;
    display: flex;
    width:100%;
  }
  &_icon{
    margin-top: 4px;
    opacity: 100%;
  }
  &_text{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}
.subTitile {
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;;
  margin-bottom: 24px;
}

.changeForm{
  width: 335px;
  height: 44px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding: 12px 16px;
  justify-content: start;
  align-items: center;
  font-family: $fontFamilyDefault;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  background: $background;
  box-shadow: 2px 2px 5px;
  color: #2B3558;
  border-radius: 6px;
}

.buttons{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

@media (max-width: $bpTablet) {
  .wrapper{
    width: 328px;
    height: 240px;
  }
  .subTitile{
    font-size: 16px;
  }
}

@media (max-width: $bpMobileLarge) {
  .wrapper{
    width: 92vw;
    height: 240px;
    font-size: 13px;
  }
  .subTitile{
    display: none;
  }
  .changeForm{
    width: 92vw;
  }
}

@media (max-width: $bpMobileMedium) {
  .wrapper{
    width: 92vw;
    height: 240px;
    font-size: 13px;
  }
  .subTitile{
    display: none;
  }
  .changeForm{
    width: 92vw;
  }
}

@media (max-width: $bpMobileSmall) {
  .wrapper{
    width: 92vw;
    height: 240px;
    font-size: 13px;
  }
  .subTitile{
     display: none;
   }
  .changeForm{
    width: 92vw;
  }
}
