@import "../../../../styles/variables.scss";

.items {
  max-width: 968px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 24px;
  align-items: center;

  .title{
    font-weight: 600;
    font-size: 20px;
  }

  @media (max-width: $bpTablet) {
    grid-template-columns: none;
    margin-top: 26px;
    gap: 24px;
    .title{
      font-weight: 500;
      font-size: 18px;
    }
  }

  @media (max-width: $bpMobileLarge) {
    margin-top: 0;
    gap: 16px;
    grid-template-columns: 1fr;
    .title{
      font-size: 16px;
    }
  }
}
