@import "../../../styles/variables.scss";

.subscrItems {
  max-width: 860px;
  margin: 24px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;

  @media (max-width: $bpTablet) {
    gap: 24px;
    margin: 20px auto;
  }

  @media (max-width: $bpMobileLarge) {
    flex-direction: column;
    max-width: 100%;
  }
}

.button {
  bottom: 40px;
  position: sticky;
  margin: 0 auto;

  @media (max-width: $bpMobileSmall) {
    width: 100%;
  }
}

.preloader {
  display: flex;
  height: 250px;
  width: 250px;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
}
