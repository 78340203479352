@import "../../../../styles/variables.scss";

.wrapper{
  width: 100%;
}

.item {
  flex-grow: 1;
  width: 696px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 40px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  margin-bottom: 35px;
}

.subTitile {
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;;
  margin-bottom: 24px;
}
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  &_img {
    height: 250px;
    width: 250px;
  }
}
@media (max-width: $bpTablet) {
  .item{
    width: 478px;
    gap: 12px;
  }
  .subTitile{
    font-size: 16px;
  }
  .preloader {
    &_img {
      height: 250px;
      width: 250px;
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .item{
    width: 92vw;
    gap: 6px;
  }
  .subTitile{
    display: none;
  }
}

@media (max-width: $bpMobileMedium) {
  .item{
    width: 92vw;
    gap: 6px;
  }
  .subTitile{
    display: none;
  }
}

@media (max-width: $bpMobileSmall) {
  .item{
    width: 92vw;
    gap: 16px;
  }
  .subTitile{
    display: none;
  }
}
