@import "../../styles/variables.scss";

.overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.window {
  background-color: $background;

  position: relative;
  display: flex;
  flex-direction: column;

}

.closeButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  top: 20px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  span {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: $textColor;
    &:first-child {
      transform: rotate(45deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 14px;
  background-color: $EmphasiseDefault;
  &_title {
    color: $background;
  }
  &_backButton {
    display: flex;
    align-items: center;
    width: 64px;
    height: 22px;
    cursor: pointer;
  }
  &_icons {
    display: flex;
    &_auth {
    }
    a {
      display: flex;
      align-items: center;
    }
  }
}
.body {
  padding: 40px;
  flex: 1 1 auto;
  &_title {
    text-align: center;
    color: $textColor;
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &_subTitle {
    text-align: center;
    font-size: 20px;
    line-height: 26.5px;
    margin-bottom: 24px;
  }
}

@media (max-width: $bpTablet) {
  .body {
    &_title {
      text-align: center;
      color: $textColor;
      font-size: 28px;
      line-height: 40px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    &_subTitle {
      text-align: center;
      font-size: 20px;
      line-height: 26.5px;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: $bpMobileMedium) {
  .body {
    padding: 24px $horizontalPaddingContainerMobileM;
  }
}

@media (max-width: $bpMobileSmall) {
  .body {
    padding: 24px $horizontalPaddingContainerMobileS;
  }
  .header {
    padding-top: $horizontalPaddingContainerMobileS;
    padding-bottom: $horizontalPaddingContainerMobileS;
  }
  .closeButton {
    right: 12px;
    top: 12px;
  }
}
