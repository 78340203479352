@import "../../styles/variables.scss";

.slider_big_img {
  max-height: 530px;
  width: 100%;
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    bottom: 0;
    margin: 0;
    width: 6%;
    height: 100%;
    color: #2b3558;
    background: rgba(255, 255, 255, 0.65);
  }
  --swiper-navigation-size: 27px;
}

.slider_medium_img {
  max-height: 530px;
  width: 100%;
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    bottom: 0;
    margin: 0;
    width: 8%;
    height: 100%;
    color: #2b3558;
    background: rgba(255, 255, 255, 0.65);
  }
  --swiper-navigation-size: 27px;
}

.slider_small_img {
  max-height: 530px;
  width: 100%;
  .swiper-slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    top: 0;
    bottom: 0;
    margin: 0;
    width: 10%;
    height: 100%;
    color: #2b3558;
    background: rgba(255, 255, 255, 0.65);
  }
  --swiper-navigation-size: 27px;
}

@media (max-width: $bpTablet) {
  .slider_small_img {
    height: 360px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 10%;
    }
  }

  .slider_medium_img {
    height: 360px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 10%;
    }
  }

  .slider_big_img {
    height: 360px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 8%;
    }
  }
}

@media (max-width: $bpMobileMedium) {
  .slider_small_img {
    height: 240px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 12%;
    }
  }

  .slider_medium_img {
    height: 240px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 12%;
    }
  }

  .slider_big_img {
    height: 240px;
    .swiper-button-prev,
    .swiper-button-next {
      width: 11%;
    }
  }
}
