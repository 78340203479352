@import "../../styles/variables.scss";

.title {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 70px;
}

@media (max-width: $bpTablet) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: $bpMobileLarge) {
  .title {
    font-size: 15px;
    line-height: 20px;
  }
}
