@import "../../../styles/variables.scss";

.items {
  display: grid;
  grid-template-columns: minmax(300px, 520px) minmax(300px, 520px);
  gap: 40px;
  margin-top: 40px;

  @media (max-width: $bpTablet) {
    grid-template-columns: minmax(300px, 520px) minmax(300px, 520px);
    margin-top: 26px;
    gap: 24px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-top: 0;
    gap: 16px;
    grid-template-columns: minmax(0, 720px);
  }
}
