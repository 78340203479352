@import "../../../../styles/variables.scss";

.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: $darkEmphasiseDefault;
}

.errorMessage {
  //!
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  color: $errorColor;
}

.formSection {
  // !
  position: relative;
  margin-bottom: 24px;
  label {
    position: absolute;
    top: 14px;
    left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.formSection_radioWrapper {
  margin-bottom: 24px;
}

.formSection_radio {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid $border;
}

.formSection_radio_error {
  border-bottom: 1px solid $errorColor;
}

.radioTitle {
  line-height: 22px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.radioButton {
  input {
    display: none;
  }
  label {
    padding: 8px 18px;
    background: $background;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    border: solid 1px $textColor;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  input:checked + label {
    color: $background;
    background: $btnDefaultBGColor;
    border: 1px solid $lightEmphasiseDefault;
  }
}

.formSection_textarea {
  border-bottom: 1px solid $border;
  margin-bottom: 24px;
}

.description {
  // !
  text-align: center;
  line-height: 21px;
  margin-bottom: 24px;
}

.button {
  // !
  display: flex;
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  // !
  .button {
    width: 100%;
    justify-content: center;
  }
}
