@import "../../../../styles/variables.scss";

.item {
  max-width: 47%;
  flex: 1 1 auto;
  background-color: $background;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 24px 0;
  color: $textColor;

  @media (max-width: $bpTablet) {
    padding: 16px 0;
  }

  @media (max-width: $bpMobileLarge) {
    max-width: 100%;
    padding: 12px 0;
  }
}

.new_item {
  border: 3px solid rgba(255, 83, 73, 0.65);
  max-width: 47%;
  flex: 1 1 auto;
  background-color: $background;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 24px 0;
  color: $textColor;

  @media (max-width: $bpTablet) {
    padding: 16px 0;
  }

  @media (max-width: $bpMobileLarge) {
    max-width: 100%;
    padding: 12px 0;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 24px;

  @media (max-width: $bpTablet) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    padding: 0 16px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
  }
}

.warningTop {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  background-color: $lightEmphasiseDefault;
  margin-bottom: 20px;
  padding: 10px 20px;
  text-align: center;

  @media (max-width: $bpTablet) {
    height: 29px;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 16px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
  }
}

.info {
  font-style: italic;
  line-height: 19px;
  margin-bottom: 24px;
  padding: 0 24px;
  &_age {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;
    span {
      font-style: normal;
      color: $EmphasiseDefault;
    }
    &::before {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      background: url("../../../../assets/icons/age.svg") top no-repeat;
    }
  }
  &_expiration {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    span {
      font-style: normal;
      &:first-child {
        color: $EmphasiseDefault;
      }
    }

    &::before {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      background: url("../../../../assets/icons/ticket.svg") center no-repeat;
    }
  }
  &_time {
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      &:first-child {
        color: $EmphasiseDefault;
      }
    }

    &::before {
      content: "";
      display: flex;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      background: url("../../../../assets/icons/calendar.svg") center no-repeat;
    }
  }

  @media (max-width: $bpTablet) {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
    padding: 0 16px;
    &_age {
      margin-bottom: 10px;
    }
    &_expiration {
      margin-bottom: 10px;
    }
    &_time {
      margin-bottom: 10px;
    }
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
    &_age {
      margin-bottom: 4px;
    }
    &_expiration {
      margin-bottom: 4px;
    }
    &_time {
      margin-bottom: 4px;
    }
  }
}

.includes {
  margin-bottom: 24px;
  padding: 0 24px;
  &_title {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 14px;
  }
  &_items {
    display: flex;
    gap: 24px;
  }
  &_item {
    &_gym {
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../../../assets/icons/Gym.svg") center no-repeat;
        margin-bottom: 6px;
      }
    }
    &_pool {
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../../../assets/icons/Pool.svg") center no-repeat;
        margin-bottom: 6px;
      }
    }
    &_spa {
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../../../../assets/icons/Sauna.svg") center no-repeat;
        margin-bottom: 6px;
      }
    }
  }

  @media (max-width: $bpTablet) {
    margin-bottom: 16px;
    padding: 0 16px;
    &_title {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 12px;
    }
    &_item {
      &_gym {
        font-size: 12px;
      }
      &_pool {
        font-size: 12px;
      }
      &_spa {
        font-size: 12px;
      }
    }
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
    &_title {
      margin-bottom: 5px;
    }
    &_item {
      &_gym {
        &::before {
          margin-bottom: 2px;
        }
      }
      &_pool {
        &::before {
          margin-bottom: 2px;
        }
      }
      &_spa {
        &::before {
          margin-bottom: 2px;
        }
      }
    }
  }
}

.quantity {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 24px;

  &_family {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-left: 24px;
    margin-bottom: 10px;
  }

  &_text {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &_text_family {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    width: 60%;

    @media (max-width: $bpTablet) {
      font-size: 12px;
      line-height: 14px;
      width: 50%;
    }

    @media (max-width: $bpMobileLarge) {
      width: 45%;
    }

    @media (max-width: $bpMobileMedium) {
      width: 30%;
    }

    @media (max-width: $bpMobileSmall) {
    width: 40%;
    }
  }

  &_button_minus {
    width: 30px;
    height: 30px;
    background-color: $EmphasiseDefault;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    margin-left: 12px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $background;
      width: 12px;
      height: 2px;
    }
    &:disabled {
      background-color: $lightEmphasiseDefault;
      cursor: auto;
    }
  }
  &_button_plus {
    width: 30px;
    height: 30px;
    background-color: $EmphasiseDefault;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $background;
      width: 12px;
      height: 2px;
    }
    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
    &:disabled {
      background-color: $lightEmphasiseDefault;
      cursor: auto;
    }
  }
  &_value {
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    width: 24px;
    margin: 0 4px;
  }

  @media (max-width: $bpTablet) {
    margin-bottom: 16px;
    padding: 0 16px;

    &_family {
      padding-left: 16px;
      margin-bottom: 10px;
    }

    &_text {
      font-size: 12px;
      line-height: 14px;
    }
    &_button_minus {
      width: 26px;
      height: 26px;
      margin-left: 16px;
      &::before {
        width: 10px;
      }
    }
    &_button_plus {
      width: 26px;
      height: 26px;
      &::before,
      &::after {
        width: 10px;
        height: 2px;
      }
    }
    &_value {
      font-size: 13px;
      line-height: 15px;
      width: 24px;
      margin: 0 2px;
    }
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;

    &_family {
      padding-left: 12px;
      margin-bottom: 10px;
    }

    &_button_minus {
      width: 20px;
      height: 20px;
      &::before {
        width: 8px;
      }
    }
    &_button_plus {
      width: 20px;
      height: 20px;
      &::before,
      &::after {
        width: 8px;
      }
    }
  }
}

.price {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 24px;

  @media (max-width: $bpTablet) {
    margin-bottom: 16px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 15px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
  }
}

.text_minimal_count {
  color: #a3a3a3;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  padding: 0 24px;

  @media (max-width: $bpTablet) {
    margin-bottom: 16px;
    padding: 0 16px;
    font-size: 13px;
    line-height: 15px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 10px;
    padding: 0 12px;
  }
}

.discount {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
  padding: 0 24px;

  @media (max-width: $bpTablet) {
    padding: 0 16px;
    font-size: 9px;
    line-height: 11px;
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 4px;
    padding: 0 12px;
  }
}

.warningBottom {
  font-style: italic;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 24px 5px;

  @media (max-width: $bpTablet) {
    padding: 0 16px;
    font-size: 10px;
    line-height: 12px;
  }

  @media (max-width: $bpMobileLarge) {
    padding: 0 12px;
  }
}
