@import "../../../../styles/variables.scss";

.navigate {
  max-width: 534px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  &_currentDay {
    font-size: 20px;
    line-height: 23px;
    span {
      color: $EmphasiseDefault;
    }
  }
  &_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $textColor;
    -webkit-appearance: none;
    cursor: pointer;
    &_name {
      margin-top: 14px;
      line-height: 22px;
    }
    &:disabled {
      cursor: auto;
      img {
        opacity: 0.3;
      }
    }
  }

  @media (max-width: $bpTablet) {
    max-width: 328px;
    &_currentDay {
      font-size: 16px;
      line-height: 22px;
    }
    &_button {
      &_name {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: $bpMobileLarge) {
    margin-bottom: 16px;
    &_button {
      &_name {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: $bpMobileMedium) {
    max-width: 100%;
    margin-bottom: 16px;
    &_currentDay {
      margin: 0 auto;
    }
    &_button {
      &_name {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
