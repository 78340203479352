@import "../../styles/variables.scss";

.button {
  display: flex;
  margin: 0 auto;
}

@media (max-width: $bpTablet) {
  .button {
    width: 100%;
    justify-content: center;
  }
}
