@import "../../../../styles/variables.scss";

.item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 20px;
  line-height: 23px;
  font-family: $fontFamilyDefault;
  background: $background;
  color: #000000;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  cursor: pointer;
  p:first-child {
    font-weight: 500;
  }
  p:last-child {
    font-style: italic;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
  &_selected {
    p {
      color: $background;
    }
    background: $EmphasiseDefault;
  }

  @media (max-width: $bpTablet) {
    font-size: 13px;
    line-height: 15px;
    gap: 2px;
    p:last-child {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .item:hover {
    background: $hoverDefault;
  }
  .item_selected:hover {
    background: #69a826;
  }
}
