@import "../../styles/variables.scss";

.footer {
  border-top: 1px solid #bfc2cd;
  margin-top: 90px;
}

.wrapper {
  display: flex;
  padding: 50px 0;
  justify-content: space-between;
}

.logo {
  max-width: 60px;
  max-height: 60px;
  img {
    vertical-align: top;
    width: 100%;
    object-fit: cover;
  }
}

.menu {
  max-width: 343px;
  column-count: 2;
  column-gap: 10px;
}

.menu__list {
  line-height: 140%;
  font-weight: normal;
  li {
    margin-bottom: 12px;
  }
}

.contacts {
  line-height: 140%;
}

.phone {
  margin-bottom: 16px;
}

.address {
  margin-bottom: 20px;
}

@media (max-width: $bpTablet) {
  .logo {
    max-width: 40px;
    max-height: 40px;
  }

  .menu {
    max-width: 290px;
    column-gap: 4px;
  }

  .menu__list {
    font-size: 14px;
    li {
      margin-bottom: 10px;
    }
  }

  .contacts {
    font-size: 14px;
  }

  .phone {
    margin-bottom: 12px;
  }

  .address {
    margin-bottom: 16px;
  }
}

@media (max-width: $bpMobileLarge) {
  .footer {
    margin-top: 70px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0;
    justify-content: space-between;
  }

  .menu {
    margin: 0 16px 24px 16px;
    max-width: 80%;
    column-count: 3;
    column-gap: 25px;
  }

  .menu__list {
    line-height: 140%;
    li {
      margin-bottom: 12px;
    }
  }

  .contacts {
    transform: translate(-50%, 0);
    margin-left: 50%;
    text-align: center;
  }

  .button {
    margin: 0 auto;
  }
}

@media (max-width: $bpMobileMedium) {
  .menu {
    max-width: 70%;
    column-count: 2;
    column-gap: 10px;
  }

  .menu__list {
    font-size: 12px;
    li {
      margin-bottom: 8px;
    }
  }

  .contacts {
    font-size: 12px;
  }
}

@media (max-width: $bpMobileSmall) {
  .menu {
    max-width: 70%;
    column-count: 2;
    column-gap: 10px;
  }

  .menu__list {
    font-size: 12px;
    li {
      margin-bottom: 8px;
    }
  }

  .contacts {
    flex: 1 1 auto;
    font-size: 12px;
    transform: none;
    margin-left: 0;
    text-align: left;
  }

  .button {
    width: 100%;
  }
}
