
.input {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: 48px;
  padding: 13px 17px 13px 52px;
  line-height: 20px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
