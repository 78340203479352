@import "../../../../styles/variables.scss";

.price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;

  @media (max-width: $bpTablet) {
    font-size: 15px;
    line-height: 18px;
  }
}

.value {
  color: $EmphasiseDefault;
}
