@import "../../styles/variables.scss";

.btn {
  font-family: $fontFamilyDefault;
  color: $background;
  font-size: $fontSizeDefault;
  font-weight: 700;
  padding: 12px 24px;
  background: $btnDefaultBGColor;
  border-radius: 8px;
  min-width: 160px;

  display: flex;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: $btnHoverBGColor;
  }
  &:disabled {
    cursor: auto;
    background: $btnDisableBGColor;
  }
}
