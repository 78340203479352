@import "../../styles/variables.scss";

.errorMessage {
  //!
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  color: $errorColor;
  margin-bottom: 6px;
}

// .loadingMessage {
//   font-size: 14px;
//   font-weight: 700;
//   text-align: center;
//   margin-bottom: 24px;
//   color: $darkEmphasiseDefault;
// }

.formSection {
  position: relative;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  label {
    position: absolute;
    top: 12px;
    left: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.textError{
  margin-bottom: 1px;
}

.button {
  display: flex;
  margin: 0 auto;

  @media (max-width: $bpTablet) {
    width: 100%;
    justify-content: center;
  }
}
