.input {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  padding: 0 10px 6px 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  &:not(:last-child) {
    margin-right: 4px;
  }
  &:not(:first-child) {
    margin-left: 4px;
  }
}
