@import "../../styles/variables.scss";

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  &_img {
    height: 250px;
    width: 250px;
  }
}

.cartEmpty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35vh;
  font-size: 20px;
}

.cartItems {
  margin: 40px 0 25px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.totalSum {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  &_text {
    white-space: pre;
  }
  &_price {
    color: $EmphasiseDefault;
  }
}

.payForm {
  &_section {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
  &_checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &_checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    line-height: 20px;
    position: relative;
    white-space: pre-wrap;
    a {
      text-decoration: underline;
    }
    &::before {
      content: "";
      display: inline-flex;
      position: relative;
      top: 2px;
      left: 1px;
      display: flex;
      width: 24px;
      height: 24px;
      padding-right: 24px;
      margin-right: 14px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-image: url("../../assets/icons/checkboxUnactive.svg");
      background-repeat: no-repeat;
    }
  }
  &_checkbox:checked + label::before {
    top: 0px;
    left: 0px;
    background-image: url("../../assets/icons/checkboxActive.svg");
    background-repeat: no-repeat;
  }
  &_error {
    margin-left: 38px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
    color: $errorColor;
    border-top: 1px solid $errorColor;
  }
}

.button {
  margin: 24px auto 0 auto;
  span {
    color: $background;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 12px;
      width: 12px;
      height: 10px;
      background-image: url("../../assets/icons/arrowIndicRight.svg");
    }
  }
}

@media (max-width: $bpTablet) {
  .cartItems {
    margin: 26px 0 25px 0;
    gap: 24px;
  }

  .totalSum {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 25px;
  }

  .payForm {
    &_section {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    &_checkbox + label {
      font-size: 14px;
    }
  }

  .button {
    margin-top: 30px;
    span {
      color: $background;
      &::after {
        content: "";
        display: inline-block;
        margin-left: 12px;
        width: 12px;
        height: 10px;
        background-image: url("../../assets/icons/arrowIndicRight.svg");
      }
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .cartItems {
    margin: 0 0 16px 0;
    gap: 16px;
  }

  .wrapper {
    flex: 0 0 328px;
  }

  .button {
    margin-top: 16px;
  }

  .totalSum {
    margin-bottom: 16px;
  }
}
