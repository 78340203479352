@import "../../../../../styles/variables";

.subTitile {
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 24px;
}

.back{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  line-height: 140%;
}
.img{
  margin-top: 4px;
  margin-right: 10px;
  margin-bottom: 12px ;
}


.wrapper{
  width: 100%;
  max-width: 880px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}


.slider{
  width: 100%;
  max-width: 880px;
  min-width: 401px;
  max-height: 380px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  object-fit: cover;
}

.imgContainer{
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  img {
    object-fit: cover;
    &.small {
      max-width: 202px;
      min-height: 202px;
    }
  }
}


@media (max-width: $bpTablet) {
  .slider{
    max-width: 456px;
    gap: 12px
  }
  .subTitile {
    font-size: 22px;
    line-height: 140%;
  }
  .imgContainer{
    margin-top: 12px;
    gap: 12px;
    justify-content: start;
    img {
      &.small {
        max-width: 105px;
        min-width: 105px;
        min-height: 105px;
        max-height: 105px;
      }
    }
  }
  }


@media (max-width: $bpMobileLarge) {
  .slider{
    min-width: 480px;
    max-width: 630px;
    max-height: 290px;
    gap: 12px
  }
  .imgContainer{
    justify-content: center;
    gap: 12px;
    img {
      &.small {
        max-width: 102px;
        min-width: 102px;
        min-height: 102px;
        max-height: 102px;
      }
    }
  }
}

@media (max-width: $bpMobileMedium) {
  .slider{
    min-width: 200px;
    max-width: 560px;
    max-height: 290px;
    gap: 12px
  }
  .imgContainer{
    gap: 12px;
    img {
      &.small {
        max-width: 102px;
        min-width: 102px;
        min-height: 102px;
        max-height: 102px;
      }
    }
  }
}

@media (max-width: $bpMobileSmall) {
  .slider{
    min-width: 300px;
    max-width: 468px;
    gap: 11px
  }
  .imgContainer{
    gap: 11px;
    img {
      &.small {
        max-width: 102px;
        min-width: 102px;
        min-height: 102px;
        max-height: 102px;
      }
    }
  }
}