@import "../../../../styles/variables.scss";

.formSection {
  position: relative;
  margin-bottom: 14px;
}

.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: $darkEmphasiseDefault;
}

.inputsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.errorMessage {
  font-size: 14px;
  text-align: center;
  margin-top: 4px;
  color: $errorColor;
}

.description {
  // !
  text-align: center;
  line-height: 21px;
  margin-top: 24px;
  margin-bottom: 24px;
  opacity: 50%;
}

.timer_time {
  font-weight: bold;
  text-decoration: underline;
}

.resend {
  cursor: pointer;
  text-decoration: underline;
}

.button {
  display: flex;
  margin: 0 auto;
}

.input {
  border-radius: 0;
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  display: inline-block;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  padding: 0 10px 6px 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  &:not(:last-child) {
    margin-right: 4px;
  }
  &:not(:first-child) {
    margin-left: 4px;
  }
}

@media (max-width: $bpTablet) {
  .button {
    width: 100%;
    justify-content: center;
  }
}
