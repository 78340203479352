@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.profileList{
  width: 356px;
}

@media (max-width: $bpMobileLarge) {
  .profileList{
    width: 92vw;
  }
}
