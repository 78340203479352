@import "../../../styles/variables.scss";

.subTitile {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin: 40px 0 24px 0;

  @media (max-width: $bpTablet) {
    font-size: 20px;
    line-height: 24px;
    margin: 26px 0;
  }

  @media (max-width: $bpMobileLarge) {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.link {
  color: $textColor;
  line-height: 19px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  &:hover {
    color: $EmphasiseDefault;
    text-decoration: underline;
  }
  &:visited {
    text-decoration: underline;
  }
}

.button {
  margin: 0 auto 16px auto;

  @media (max-width: $bpTablet) {
    max-width: 328px;
    width: 100%;
  }

  @media (max-width: $bpMobileMedium) {
    max-width: none;
  }
}
