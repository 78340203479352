@import "../../styles/variables.scss";

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 250px;
  margin: auto;
  justify-self: center;
  align-self: center;
  img {
    object-fit: contain;
  }
}
