@import "../../../../styles/variables.scss";

.hallTitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 16px;
  width: 40%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  margin-bottom: 40px;
  border-radius: 6px;
  color: $textColor;
  font-size: 18px;
  line-height: 18px;
  &:before {
    content: "";
    width: 40px;
    height: 40px;
    background: url("../../../../assets/icons/mat.svg") center no-repeat;
    margin-right: 12px;
  }

  @media (max-width: $bpTablet) {
    display: inline-flex;
    width: 60%;
    font-size: 15px;
    line-height: 15px;
    padding: 12px;
    margin-bottom: 26px;
  }

  @media (max-width: $bpMobileLarge) {
    font-size: 13px;
    line-height: 15px;
    padding: 8px;
    margin-bottom: 16px;
  }

  @media (max-width: $bpMobileMedium) {
    width: 100%;
  }
}
