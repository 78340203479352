@import "../../../../styles/variables.scss";

.list {
  li {
    line-height: 21px;
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &::before {
      content: "";
      flex: 0 0 auto;
      width: 16px;
      height: 12px;
      margin: 4px 15px 0 0;
      background-image: url("../../../../assets/icons/checkMark.svg");
      background-repeat: no-repeat;
    }
  }
}

.button {
  display: flex;
  margin: 24px auto 0 auto;
  span {
    color: $background;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 12px;
      width: 12px;
      height: 10px;
      background-image: url("../../../../assets/icons/arrowIndicRight.svg");
    }
  }
}

@media (max-width: $bpTablet) {
  // !
  .button {
    width: 100%;
    justify-content: center;
  }
}
