@import "../../../../styles/variables.scss";

.item {
  display: flex;
  flex-direction: column;
}

.img {
  width: 100%;
  max-height: 320px;
  flex: 1 1 auto;
  img {
    width: 100%;
    height: 100%;
    max-height: 320px;
    object-fit: cover;
  }
}

// .slider {
//   max-width: 520px;
//   max-height: 320px;
// }

.title {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin: 24px 0;

  @media (max-width: $bpTablet) {
    font-size: 15px;
    line-height: 18px;
    margin: 16px 0;
  }

  @media (max-width: $bpMobileLarge) {
    font-size: 16px;
    line-height: 19px;
    margin: 12px 0;
  }
}

.button {
  margin: 0 auto;
}
