@import "../../../../styles/variables.scss";

.loadingMessage {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  color: $darkEmphasiseDefault;
}

.acceptForm {
  &_section {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    &:first-child {
      label {
        &::before {
          top: 2px;
        }
      }
    }
  }
  &_checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &_checkbox + label {
    display: inline-flex;
    align-items: flex-start;
    user-select: none;
    line-height: 20px;
    position: relative;
    white-space: pre-wrap;
    a {
      text-decoration: underline;
    }
    &::before {
      content: "";
      display: inline-flex;
      position: relative;
      top: 0px;
      left: 1px;
      display: flex;
      width: 24px;
      height: 24px;
      padding-right: 24px;
      margin-right: 14px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-image: url("../../../../assets/icons/checkboxUnactive.svg");
      background-repeat: no-repeat;
    }
  }
  &_checkbox:checked + label::before {
    top: 0px;
    left: 0px;
    background-image: url("../../../../assets/icons/checkboxActive.svg");
    background-repeat: no-repeat;
  }
  &_error {
    margin-left: 38px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-top: 4px;
    color: $errorColor;
    border-top: 1px solid $errorColor;
  }
}

.button {
  margin: 26px auto 0 auto;
  span {
    color: $background;
  }
}

@media (max-width: $bpTablet) {
  .cartItems {
    margin: 26px 0 25px 0;
    gap: 24px;
  }

  .totalSum {
    &_price {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .acceptForm {
    &_section {
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
    &_checkbox + label {
      font-size: 14px;
    }
  }

  .button {
    margin-top: 25px;
  }
}

@media (max-width: $bpMobileLarge) {
  .cartItems {
    margin: 0 0 16px 0;
    gap: 16px;
  }

  .wrapper {
    flex: 0 0 328px;
  }

  .button {
    margin-top: 21px;
  }
}
