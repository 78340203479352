@import "../../styles/variables.scss";

.icons {
  &__callMeBack {
    a {
      display: none;
      align-items: center;
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .icons {
    &__callMeBack {
      a {
        display: flex;
      }
    }
  }
}

@media (max-width: $bpMobileSmall) {
  .icons {
    &__callMeBack {
      a {
      }
    }
  }
}
