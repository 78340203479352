@import "../../../styles/variables.scss";

.subTitile {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 700;
}

.image {
  margin: 0 auto 24px auto;
  width: 575px;
  height: 375px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.linkButtons {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0 auto;
  min-width: 204px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

@media (max-width: $bpTablet) {
  .image {
    margin: 0 auto 20px auto;
    width: 312px;
    height: 228px;
  }
}

@media (max-width: $bpMobileMedium) {
  .image {
    width: 100%;
  }
  .button {
    width: 100%;
  }
}
