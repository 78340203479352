@import "../../styles/variables";

.item {
  width: 356px;
  height: 71px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  padding: 16px;
  justify-content: flex-start;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  color: $textColor;
  border-radius: 6px;
  &_trash {
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
    margin-left: auto;
  }
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}
.text{
  margin-right: 4px;
  font-style: italic;
  font-size: 14px;
  &_phone{
    font-size: 14px;
    font-style: normal;
  }
}

.content{
  display: flex;
  flex-direction: row;
}

.img{
  width: 30px;
  height: 30px;
}

.title{
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 4px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  &_cancel {
    background: $btnCencelBGColor;
  }
}
.button {
  background: $btnCencelBGColor;
  opacity: 50%;
  color: $textColor;
}

@media (max-width: $bpTablet) {

}
@media (max-width: $bpMobileLarge) {
  .item{
    gap: 12px;
    width: 92vw;

  }
  .text{
    font-size: 13px;
    line-height: 15px;
    &_phone{
      font-size: 13px;
      line-height: 15px;
      font-style: normal;
    }
  }
  .title{
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: $bpMobileMedium) {
  .item{
    gap: 12px;
    width: 92vw;
  }
  .text{
    font-size: 13px;
    line-height: 15px;
    &_phone{
      font-size: 13px;
      line-height: 15px;
      font-style: normal;
    }
  }
  .title{
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: $bpMobileSmall) {
  .item{
    gap: 12px;
    width: 92vw;
  }
  .text{
    font-size: 13px;
    line-height: 15px;
    &_phone{
      font-size: 13px;
      line-height: 15px;
      font-style: normal;
    }
  }
  .title{
    font-size: 14px;
    line-height: 16px;
  }
}
