@import "../../../../styles/variables.scss";

.item {
  flex: 0 1 512px;
  padding: 24px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  &:not(:last-child) {
    margin-right: 40px;
  }
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.button {
  margin: 0 auto 20px auto;
}

.description {
  text-align: center;
  line-height: 21px;
}

@media (max-width: $bpTablet) {
  .item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 16px;
  }

  .button {
    width: 100%;
  }

  .description {
    font-size: 15px;
    line-height: 20px;
  }
}

@media (max-width: $bpMobileLarge) {
  .item {
    flex: 0 1 auto;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
