@import "../../../styles/variables.scss";

.subTitile {
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin: 40px 0;

  @media (max-width: $bpTablet) {
    font-size: 20px;
    line-height: 24px;
    margin: 26px 0;
  }

  @media (max-width: $bpMobileLarge) {
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.hallTitle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 16px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  margin-bottom: 40px;
  border-radius: 6px;
  color: $textColor;
  font-size: 16px;
  line-height: 18px;
  &:before {
    content: "";
    width: 40px;
    height: 40px;
    background: url("../../../assets/icons/mat.svg") center no-repeat;
    margin-right: 12px;
  }

  @media (max-width: $bpTablet) {
    font-size: 13px;
    line-height: 15px;
    padding: 12px;
    margin-bottom: 26px;
  }

  @media (max-width: $bpMobileLarge) {
    font-size: 13px;
    line-height: 15px;
    padding: 8px;
    margin-bottom: 16px;
    width: 100%;
  }
}

.link {
  color: $textColor;
  line-height: 19px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  &:hover {
    color: $EmphasiseDefault;
    text-decoration: underline;
  }
  &:visited {
    text-decoration: underline;
  }
}
