@import "../../styles/variables";

.textWrapper{
  font-weight: 400;
  font-size: $fontSizeDefault;
  font-family: $fontFamilyDefault;
  line-height: 140%;
  width: 73vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.title{
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  &_number{
    margin-right: 18px;
  }
}


@media (max-width: $bpTablet) {
 .title{
   font-size: 14px;
   line-height: 140%;
 }
  .textWrapper{
    width: 80vw;
    margin-top: 26px;
  }
}
@media (max-width: $bpMobileLarge) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
  }
}
@media (max-width: $bpMobileMedium) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
  }
}

@media (max-width: $bpMobileSmall) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
  }
}

