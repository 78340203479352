@import "mixins";
@import "fonts";
@import "variables";
@import "null";
html,
body,{
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wrapper {
  /*overflow-y: auto;*/
  height: 100%;
  background-color: $background;
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: $totalWidthContainer;
  padding-left: $horizontalPaddingContainer;
  padding-right: $horizontalPaddingContainer;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $bpMobileMedium) {
    padding-left: $horizontalPaddingContainerMobileM;
    padding-right: $horizontalPaddingContainerMobileM;
  }

  @media (max-width: $bpMobileSmall) {
    padding-left: $horizontalPaddingContainerMobileS;
    padding-right: $horizontalPaddingContainerMobileS;
  }
  &--mobileFull {
    @media (max-width: $bpMobileLarge) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--textAlignCenter {
    text-align: center;
  }
}

main {
  flex: 1 1 auto;
}

abbr[title] {
  text-decoration: none; // for calendar component
}
