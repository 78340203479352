@import "../../styles/variables.scss";

.item {
  width: 356px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  padding: 0px;
  gap: 16px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  color: #000000;
  &_plusItem {
    cursor: pointer;
    width: 328px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 17px;
    gap: 12px;
  }
}

.wrapper{
  width: 100%;
}

.otherMethodsItem {
  width: 328px;
  height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 16px;
  justify-content: flex-start;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: $background;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.08);
  color: #2b3558;
  cursor: pointer;
  &_trash {
    margin-left: 70px;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
  }
  &:hover {
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  }
}

.subTitile {
  text-align: left;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  margin-bottom: 24px;
  &__otherMethods {
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    line-height: 140%;
    margin-bottom: 24px;
    margin-top: 20px;
  }
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  &_img {
    height: 250px;
    width: 250px;
  }
}

.textModal{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.leftButton{
  margin: 0 auto;
  display: flex;
}
.rightButton{
  margin: 0 auto;
  display: flex;
}

.modalButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  button {
    width: 55%;
    position: relative;
    z-index: 2;

    &:last-child {
      position: absolute;
      top: 0;
      left: 45%;
      z-index: 1;
    }
  }
}
.newStyles{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  button {
    width: 55%;
    position: relative;
    z-index: 2;
    &:last-child {
      position: absolute;
      top: 0;
      left: 45%;
      z-index: 2;
    }
  }
}

.greyButton{
  background: #E2E2E2;
  color: black;
  font-weight: 400;
  font-size: 16px;
}

.activeButton{
  font-weight: 400;
  font-size: 16px;
}

.title{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}

@media (max-width: $bpTablet) {
  .subTitile {
    font-size: 16px;
  }
  .preloader {
    &_img {
      height: 250px;
      width: 250px;
    }
  }
}
@media (max-width: $bpMobileLarge) {
  .subTitile {
    display: none;
  }
  .item{
    width: 92vw;
  }
}

@media (max-width: $bpMobileMedium) {
  .subTitile {
    display: none;
  }
  .item{
    width: 92vw;
  }
}

@media (max-width: $bpMobileSmall) {
  .subTitile {
    display: none;
  }
  .item{
    width: 92vw;
  }
}
