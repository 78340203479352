@import "../../styles/variables.scss";

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  gap: 8px;
  text-decoration: underline;
  flex-direction: column;
}

.links_register {
  font-family: $fontSomeLinksDefault;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  align-items: center;
  margin-top: 24px;
}

.links_register a {
  text-decoration: underline;
}

.link {
  color: $linkFontColor;
  font-family: $fontSomeLinksDefault;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline;
  &:hover {
    color: $linkFontColor;
    text-decoration: underline;
  }
  &_forgot {
    color: $textColor;
    opacity: 50%;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.button {
  // !
  display: flex;
  margin: 0 auto;
}
