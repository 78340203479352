@import "../../styles/variables";

.textWrapper{
  font-weight: 400;
  font-size: $fontSizeDefault;
  font-family: $fontFamilyDefault;
  line-height: 140%;
  width: 788px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
  li {
    margin-top: 10px;;
  }
  &_bold{
    font-weight: 600;
    font-size: 18px;
  }
}

.req{
  margin-left: 40px;
}

.liText{
  li {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  span{
    padding-right: 15px;
  };
}

.liTextMore{
  li {
    display: flex;
    align-items: flex-start;
  }
  span{
    padding-right: 6px;
  };
}

.title{
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  &_number{
    margin-right: 25px;
  }
}

.subText{
  margin-left: 55px;
  li {
    display: flex;
    align-items: flex-start;
  }
  span{
    padding-right: 14px;
  };
}

.subTextMore{
  margin-left: 55px;
  li {
    display: flex;
    align-items: flex-start;
  }
  span{
    padding-right: 6px;
  };
}

@media (max-width: $bpTablet) {
 .title{
   font-size: 14px;
   line-height: 140%;
 }
  .textWrapper{
    width: 80vw;
    margin-top: 26px;
  }
}
@media (max-width: $bpMobileLarge) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
  }
  .subText{
    margin-left: 35px;
  }
}
@media (max-width: $bpMobileMedium) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
  }
  .subText{
    margin-left: 40px;
  }
}

@media (max-width: $bpMobileSmall) {
  .textWrapper{
    margin-top: 0px;
    width: 92vw;
    li {
     font-size: 14px;
    }
  }
  .req{
    margin-left: 36px;
    font-size: 14px;
  }
  .subText{
    margin-left: 40px;
  }
  .subTextMore{
    margin-left: 35px;
  }
}

