@import "../../../../styles/variables.scss";

.item {
  width: 100%;
  padding: 24px;
  background-color: $background;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  &:not(:last-child) {
    margin-bottom: 24px;

    @media (max-width: $bpTablet) {
      margin-bottom: 16px;
    }
  }
}

.header {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: $bpTablet) {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 18px;
  }
}

.title {
  color: $textColor;
}

.price {
  color: #7dc82f; // TODO: change after merge MainPage - $EmphasiseDefault
}
