@import "../../styles/variables.scss";

.slider {
  img {
    max-height: 530px;
  }
}

.subTitile {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  margin: 0px auto 24px auto;
  max-width: 490px;
}

.buttonWrap {
  text-align: center;
}

.button {
  margin: 0 auto 32px auto;
}

.preloader {
  display: flex;
  height: 250px;
  margin: 0 auto;
}

.moreFotosLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 28px;
  &::after {
    content: "";
    display: flex;
    width: 28px;
    height: 28px;
    background: url("../../assets/icons/linkArrowRight.svg") center no-repeat;
  }
}

@media (max-width: $bpTablet) {
  .subTitile {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .button {
    margin-bottom: 24px;
  }

  .preloader {
    height: 250px;
    width: 250px;
  }

  .moreFotosLink {
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

@media (max-width: $bpMobileLarge) {
  .subTitile {
    font-size: 16px;
    line-height: 22px;
  }

  .button {
    margin-bottom: 16px;
  }

  .moreFotosLink {
    font-size: 16px;
    line-height: 22px;
    margin-top: 17px;
    margin-bottom: 25px;
  }
}

@media (max-width: $bpMobileMedium) {
  .preloader {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: $bpMobileSmall) {
  .subTitile {
    padding-left: $horizontalPaddingContainerMobileS;
    padding-right: $horizontalPaddingContainerMobileS;
  }
} ;
