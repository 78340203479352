@import "../../styles/variables.scss";

.icons {
  display: flex;
  &__cart {
    a {
      display: flex;
      align-items: center;
      margin-left: 24px;
      span {
        margin-left: 7px;
        transition: color .2s ease-in-out;
      }
      svg{
        transition: stroke .2s ease-in-out;
        stroke: black;
      }
      &:hover {
        span{
          color: $EmphasiseDefault;
        }
        svg{
          stroke: $EmphasiseDefault;
        }
        
      }
    }
  }
}

@media (max-width: $bpMobileLarge) {
  .icons {
    &__cart {
      a {
        margin-left: 16px;
        span {
          display: none;
        }
        svg{
          stroke: none;
        }
      }
    }
  }
}

// @media (max-width: $bpMobileSmall) {
//   .icons {
//     &__cart {
//       a {
//         margin-left: 15px;
//       }
//     }
//   }
// }
