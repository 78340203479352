@import "../../styles/variables.scss";

.wrapper {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  &__wrapper{
    width: 650px;
    height: 450px;
  }
}

.info {
  flex: 1 1 50%;
  &_item {
    margin-bottom: 16px;
    &_label {
      margin-bottom: 8px;
      line-height: 22px;
    }
    &_content {
      line-height: 22px;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }
  }
}

.button {
  margin: 0 auto;
}

.phone {
  &::before {
    background: url("../../assets/icons/phoneInput.svg") no-repeat 50%;
  }
}

.address {
  &::before {
    background: url("../../assets/icons/adressFitness.svg") no-repeat 50%;
  }
}

.email {
  &::before {
    background: url("../../assets/icons/emailTennis.svg") no-repeat 50%;
  }
}

.form {
  flex: 1 1 50%;
}
@media (max-width: $bpTablet) {
  .map__wrapper{
    width: 640px;
    height: 380px;
  }
}
@media (max-width: $bpMobileLarge) {
  .wrapper {
    margin-top: 0;
    flex-direction: column;
  }
  .info {
    margin-bottom: 24px;
  }
  .map__wrapper{
    width: 328px;
    height: 240px;
  }
}

@media (max-width: $bpMobileMedium) {
  .button {
    width: 100%;
  }
}
