@import '../../styles/variables.scss';

.icons {
	display: flex;
	&__auth {
		a {
			&:hover {
				span {
					color: $EmphasiseDefault;
				}
				svg path{
					stroke: $EmphasiseDefault;
				}
			}
			display: flex;
			align-items: center;
			span {
				margin-left: 11px;
				transition: color 0.2s ease-in-out;
			}
			svg path{
				transition: stroke .2s ease-in-out;
			}
		}
	}
	&__auth_mobileMenu {
		a {
			display: flex;
			align-items: center;
			img {
				width: 24px;
				height: 24px;
			}
			span {
				margin-left: 0;
				font-size: 16px;
				color: $background;
			}
		}
	}
}

@media (max-width: $bpMobileLarge) {
	.icons__auth {
		display: none;
	}
}
