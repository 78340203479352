@import "../../../../styles/variables";

.wrapper {
  width: 328px;
  height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 24px;
  line-height: 21px;
  font-family: $fontFamilyDefault;
  font-size: $fontSizeDefault;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.12);
  color: #2B3558;
  &_header{
    font-family: $fontFamilyDefault;
    color: $textColor;
    line-height: 23px;
    font-weight: 600;
    font-size: 20px;
    box-shadow: none;
  }
}

.includes_item{
  img{
    margin-top: -2px;
  }
  font-size: 16px;
  width: 274px;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  flex-direction: row;
  gap: 13px;
  margin-bottom: 16px;
  line-height: 19px;
  font-style: italic;
}

@media (max-width: $bpTablet) {
  .wrapper{
    width: 233px;
    height: 132px;
    gap: 12px;
    padding: 12px;
    &_header{
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .includes_item{
    gap: 12px;
    width: 209px;
    line-height: 16px;
  }
  .includes_item_text{
    height: 16px;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (max-width: $bpMobileLarge) {
  .wrapper{
    width: 92vw;
    height: 108px;
    font-size: 13px;
  }
  .includes_item{
    width: 90vw;
  }
  .includes{
    margin-left: 12px;
  }
}

@media (max-width: $bpMobileMedium) {
  .wrapper{
    width: 92vw;
    height: 108px;
    font-size: 13px;
  }
  .includes_item{
    width: 90vw;
  }
  .includes{
    margin-left: 12px;
  }
}

@media (max-width: $bpMobileSmall) {
  .wrapper{
    width: 92vw;
    height: 108px;
    font-size: 13px;
  }
  .includes{
    margin-left: 12px;
  }
  .includes_item{
    width: 90vw;
    margin-bottom: 0px;
  }
}
